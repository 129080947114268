import React from "react";
import ReactMarkdown from 'react-markdown'
export const ReactMarkdownInterpreter = ({
    content
}) => {
    const result = typeof content === "string"
        ?   <ReactMarkdown
                children={ content }
            />
        : content
    return (
       <>
        { result }
       </>
    )
}
